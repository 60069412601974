


































import { defineComponent, PropType } from '@vue/composition-api';
import { ITrend } from '@/models/trends';

// @ is an alias to /src
export default defineComponent({
  props: {
    trend: {
      type: Object as PropType<ITrend>,
      required: true,
    },
    route: String,
    showText: Boolean,
    layout: String,
  },
});
