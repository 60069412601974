import axios from 'axios';
import { ITrend, ITrendContentImage } from '@/models/trends';
import { Resolve, IData } from '.';
import config from './config';

const url = process.env.VUE_APP_SERVER_PATH;

export const getTrends = (event: number) => new Promise((resolve: Resolve<IData<ITrend>>, reject) => {
  axios
    .get(`${url}/v2/trends?event=${event}&page_size=100`, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});
export const getTrend = (trend: number) => new Promise((resolve: Resolve<ITrend>, reject) => {
  axios
    .get(`${url}/v2/trends/${trend}`, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const addTrendImages = (trendImage: ITrendContentImage) => new Promise((resolve: Resolve<ITrendContentImage>, reject) => {
  axios
    .post(`${url}/v2/trend-content-images`, trendImage, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});
