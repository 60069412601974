
































































































































































































































































































































































// @ is an alias to /src
import { MutationPayload } from 'vuex';
import { defineComponent, reactive } from '@vue/composition-api';
import Sidebar from '@/components/sidebar.vue';
import { ISession, ISessionParams, IStage } from '@/models/session';
import { getEventSessions, getExhibitorSessions, getStages } from '@/api/sessions';
import router from '@/router';
import { IExhibitorsParams } from '@/store/modules/exhibitors';
import { IExhibitor } from '@/models/exhibitor';
import { IExhibitorCard } from '@/partials';
import ExhibitorCard from '@/partials/ExhibitorCard.vue';
import store from '@/store';
import SessionCard from '@/partials/SessionCard.vue';
import TrendCard from '@/partials/TrendCard.vue';
import { getExhibitors } from '@/api/exhibitors';
import { IProductItem } from '@/models/product';
import ProductCard from '@/partials/ProductCard.vue';
import { ICategory } from '@/models/category';
import { getCategories } from '@/api/categories';
import { getProductList } from '@/api/products';
import { IProductParams } from '@/store/modules/products';
import AWSStream from '@/components/aws-stream.vue';
import { ITrend } from '@/models/trends';
import { getTrends } from '@/api/trends';
import useContext, { IUseContext } from '@/composition/context';
import useEvent from '@/composition/events';

export default defineComponent({
  components: {
    Sidebar,
    ExhibitorCard,
    SessionCard,
    ProductCard,
    AWSStream,
    TrendCard,
  },
  setup() {
    const { contextVisitor, translations, contextEmbedUrl, contextSession, contextEvent } =
      useContext() as unknown as IUseContext;

    const { eventHasValidDates, eventIsFinished, eventStarted } = useEvent();

    const state = reactive({
      categories: [] as ICategory[],
      stages: [] as IStage[],
      trends: [] as ITrend[],
      productsSponsored: [] as IProductItem[],
      featuredSessions: [] as ISession[],
      upcomingSessions: [] as ISession[],
      exhibitorsSponsored: [] as IExhibitor[],
    });

    const getCroppedImage = (url: string | undefined) => {
      if (!url || url === '') {
        return undefined;
      }
      const bucketUrl = 'f.beamian.com';
      const indexOfBucket = url.indexOf(bucketUrl);

      const imagePath = url.substr(indexOfBucket + bucketUrl.length + 1, url.length);

      return `https://d2jgquhpz530bi.cloudfront.net/300x300/${imagePath}`;
    };

    const cardConfig = (exhibitor: IExhibitor) => {
      const config: IExhibitorCard = {
        name: exhibitor.name,
        logo: getCroppedImage(exhibitor.logo_url),
        route: `/event/${event}/exhibitor/${exhibitor.id}`,
      };
      return config;
    };

    const handleLink = () => {
      if (
        contextEvent.value.settings &&
        !contextEvent.value.settings.custom_action_label &&
        contextEvent.value.settings.custom_action_link
      ) {
        window.open(contextEvent.value.settings.custom_action_link, '_blank');
      }
    };

    const preview = router.currentRoute.path.includes('new-') as boolean;

    const event = parseInt(router.currentRoute.params.event, 10);

    const categoriesParams = {
      event,
    };

    getCategories(categoriesParams).then((response) => {
      state.categories = response.data.results;
    });

    getStages(event).then((response) => {
      state.stages = response.data.results;
    });

    getTrends(event).then((response) => {
      state.trends = response.data.results;
    });

    const today = new Date();
    const todayString = `${today.toISOString().split('.')[0]}Z`;

    getEventSessions({
      event,
      end_date_gte: todayString,
      page: 1,
      is_published: true,
      page_size: 4,
      reset: true,
      hide_on_calendar: false,
    }).then((response) => {
      state.upcomingSessions = response.data.results.filter(
        (session) => !session.name.includes('checkin'),
      );
    });

    const productsParams: IProductParams = {
      event,
      page: 1,
      page_size: 8,
      home_page: true,
    };

    getProductList(productsParams).then((response) => {
      state.productsSponsored = response.data.results;
    });

    if (event === 471) {
      const v2SessionsParams: ISessionParams = {
        event,
        page: 1,
        page_size: 3,
        private: false,
      };

      getExhibitorSessions(v2SessionsParams).then((response) => {
        state.featuredSessions = response.data.results.filter(
          (session) => session.name !== 'check-in' && session.name !== 'checkin',
        );
      });
    } else if (event === 462) {
      const v2SessionsParams: ISessionParams = {
        event,
        page: 1,
        page_size: 3,
        private: false,
        hide_on_calendar: false,
      };
      getEventSessions(v2SessionsParams).then((response) => {
        state.featuredSessions = response.data.results.filter(
          (session) => session.name !== 'check-in' && session.name !== 'checkin',
        );
      });
    }

    const params: IExhibitorsParams = {
      page: 1,
      event,
      page_size: 6,
      reset: true,
      home_page: true,
      hidden: false,
      status: 'registered',
    };

    getExhibitors(params).then((response) => {
      state.exhibitorsSponsored = response.data.results;
    });

    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setLanguage') {
        getCategories(categoriesParams).then((response) => {
          state.categories = response.data.results;
        });
      }
    });

    return {
      state,
      event,
      preview,
      translations,
      contextEmbedUrl,
      contextSession,
      contextVisitor,
      contextEvent,
      handleLink,
      cardConfig,
      eventHasValidDates,
      eventIsFinished,
      eventStarted,
    };
  },
  created() {
    const unsubscribe = store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setContextSession') {
        setInterval(() => {
          if (this.contextSession.id) {
            this.$gtag.event(`session_watched_${this.contextSession.id} `, {
              event_category: `event_${this.event}`,
              event_label: `visitor_${this.contextVisitor.id}`,
              value: 30,
            });
          }
        }, 30000);
        unsubscribe();
      }
    });
  },
});
