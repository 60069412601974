

















































import { defineComponent, PropType } from '@vue/composition-api';
import { IExhibitorCard } from '.';

// @ is an alias to /src
export default defineComponent({
  props: {
    config: {
      type: Object as PropType<IExhibitorCard>,
      required: true,
    },
    isDetailed: Boolean,
    isCompact: Boolean,
    nameHidden: Boolean,
  },
});
