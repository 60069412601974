import useContext from './context';

const staging = process.env.VUE_APP_SERVER_PATH !== 'https://api.beamian.com/api';

const { userAdmin, contextEvent } = useContext();

const eventStarted = (): boolean => {
  const currentDate = new Date();
  const eventStartDate = new Date(contextEvent.value.start_date);
  return staging || userAdmin.value ? true : eventStartDate <= currentDate;
};

const eventIsFinished = (): boolean => {
  const currentDate = new Date();
  const eventEndDate = new Date(contextEvent.value.end_date);
  return staging || userAdmin.value ? false : currentDate >= eventEndDate;
};

const eventHasValidDates = (): boolean => eventStarted() && !eventIsFinished();

export default function useEvent() {
  return {
    eventStarted, eventIsFinished, eventHasValidDates,
  };
}
